import React from "react";
import Image from "next/image";

const MobileLarge2 = ({ ...props }) => {

	const prop = props;

	return (
		<div className="mobile-large2">
			<a href={prop.link} className="article-image-container">
				<Image
					src={prop.image}
					alt="article"
					layout="fixed"
					objectFit="cover"
					// priority
					width={448}
					height={298}
					quality={70}
				/>
			</a>
			<a href={prop.link} className="title">{prop.title}</a>
			<p className="excerpt">
				{prop.excerpt}
			</p>
			<div className="author">
				<p href={prop.authorSlug} className="author-name">
					By&nbsp;
					<a href={prop.authorSlug}>
						{prop.authorName}
					</a>
				</p>
			</div>

			<style jsx>
				{`
            .excerpt {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;
              color: #fff;

            }
            .author {
              display: flex;
              align-items: center;
              gap: 10px;
              margin: 16px 0 0 0;
            }
            .article-image-container {
              display: grid;
              width: 100%;
              overflow: hidden;
              aspect-ratio: 3/2;
              margin: 0 0 16px 0;
            }
            .image-article {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .title {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
              color: #fff;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

            }
            .author-name {
              font-family: Roboto;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0em;
              text-align: left;
              color: #fff;
              margin: 0;
            }
            .author-name a{
              text-transform: uppercase;
              color: #fff;
            }
          `}
			</style>
		</div>
	);

};

export default MobileLarge2;
